.treeNodes{
    cursor: pointer;
    pointer-events: all;
}

.treeNode rect {
    stroke: #aaa;
    stroke-width: 1.5px;
}

.treeNode circle {
    stroke: black;
    stroke-width: 2px;
}

.treeNode text {
    font: 12px sans-serif;
    pointer-events: none;
}

.treeLink {
    fill: none;
    stroke: #aaa;
    stroke-opacity: 1;
    stroke-width: 1.5px;
}

#dragNode {
    stroke: black;
    filter: drop-shadow(0px 0px 5px rgb(0 0 0 / 0.8));
}
